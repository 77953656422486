import React from "react";

const Intro = ({ onDelete }) => (
  <div>
    <div>
      <img alt="deleting computer gif" src="img/del_comp.gif" />
    </div>
    <button onClick={onDelete}>Delete</button>
  </div>
);

Intro.metadata = {
  title: "", // Level title
  author: "revelcw", // Full name
  solution: "", // How to proceed
};

export default Intro;
