import React, { useState } from "react";

const Level005 = ({ onDelete }) => {
  const [data, setData] = useState(true);
  return (
    <>
      <div>
        <div>
          <button data-white="true" onClick={() => setData(!data)}>
            Trigger
          </button>
        </div>
        <div
          style={data ? { backgroundColor: "black" } : {}}
          className="Level005cover"
        >
          <button style={data ? { display: "none" } : {}} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

Level005.metadata = {
  title: "Cover your button", // Level title
  author: "revelcw", // Full name
  solution: 'Click the "Toggle" button to hide the black patch', // How to proceed
};

export default Level005;
