import React, { useState } from "react";

const Level010 = ({ onDelete }) => {
  const [data, setData] = useState(false);
  return (
    <>
      <h4>Delete User</h4>
      <hr className="Level010hr" />
      <img className="small-icon" src="img/switch_delete_warning.png" />
      <div className="Level010text">
        All saved data for this user will be deleted
      </div>
      <span>
        <label class="Level010checkboxcont">
          <input
            onChange={() => {
              setData(!data);
            }}
            type="checkbox"
          />
          <span className="Level010span"></span>
          <div className="Level010delete">Delete</div>
        </label>
      </span>
      <button
        onClick={data ? onDelete : ""}
        data-white="true"
        className="long-button"
      >
        Next
      </button>
    </>
  );
};

Level010.metadata = {
  title: "Mr. Mario", // Level title
  author: "revelcw", // Full name
  solution: 'Click the delete checkmark and then click "Next"', // How to proceed
};

export default Level010;
