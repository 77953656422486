import React, { useState } from "react";

const Level011 = ({ onDelete, goToLevel }) => {
  const [data, setData] = useState(false);
  return (
    <>
      <img className="small-icon" src="img/switch_delete_warning.png" />
      <div className="margin">Caution</div>
      <div className="Level011middlecont">
        <img className="small-icon" src="img/mycomputerround.png" />
        <span className="level011text">
          Data cannot be recovered once it has been deleted.
          <br /> Delete this data?
        </span>
      </div>
      <button className="long-button" data-switch="true" onClick={onDelete}>
        Delete this user
      </button>
      <button
        onClick={() => {
          goToLevel(9);
        }}
        data-white="true"
      >
        Cansle
      </button>
    </>
  );
};

Level011.metadata = {
  title: "Mr. Mario II", // Level title
  author: "Jane Doe", // Full name
  twitter: "boobooxk4", // Twitter username
  github: "123yodlel", // Github username
  solution: "Press the top button, then slide the arrow to the dog icon.", // How to proceed
};

export default Level011;
