import React, { useState } from "react";

const Level003 = ({ onDelete }) => {
  const [data, setData] = useState(0);
  return (
    <>
      <div>
        <input
          onChange={(ev) => {
            setData(parseInt(ev.target.value, 10));
          }}
          id="test"
          defaultValue="0"
          max="100"
          type="range"
        />
      </div>
      <div>{data}</div>
      <button
        data-white={data != 100}
        style={{ opacity: data / 100 }}
        onClick={() => {
          if (data === 100) {
            onDelete();
          }
        }}
      >
        Delete
      </button>
    </>
  );
};

Level003.metadata = {
  title: "It's coming into focus!", // Level title
  author: "revelcw", // Full name
  solution:
    "Slide the bar across the right (100). The delete button will appear.", // How to proceed
};

export default Level003;
