import React, { useState } from "react";

const Level006 = ({ onDelete }) => {
  return (
    <>
      <div>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
      </div>
      <div>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
      </div>
      <div>
        <button>Delete</button>
        <button>Delete</button>
        <button onClick={onDelete}>Delete</button>
        <button>Delete</button>
      </div>
      <div>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
        <button>Delete</button>
      </div>
    </>
  );
};

Level006.metadata = {
  title: "Woah! Which one?", // Level title
  author: "revelcw", // Full name
  solution: "Click the delete button 3 across and 3 down", // How to proceed
};

export default Level006;
