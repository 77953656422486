import React, { useState } from "react";

const Level008 = ({ onDelete }) => {
  const [data, setData] = useState(false);
  return (
    <>
      <div className="Level008spinner">
        <button className="Level008button" onClick={onDelete}>
          Delete
        </button>
      </div>
    </>
  );
};

Level008.metadata = {
  title: "Like a wagon wheel", // Level title
  author: "revelcw", // Full name
  solution: "Click the button", // How to proceed
};

export default Level008;
