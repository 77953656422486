import React, { useState } from "react";

const Level009 = ({ onDelete }) => {
  const [data, setData] = useState(0);
  if (data === 100) {
    return (
      <>
        <div>{data}</div>
        <div>
          <button data-white="true" onClick={() => setData(1)}>
            Go to 1!
          </button>
        </div>
        <button onClick={onDelete}>Delete</button>
      </>
    );
  } else {
    return (
      <>
        <div>
          <div>{data}</div>
          <button onClick={() => setData(data + 1)}>Delete</button>
        </div>
      </>
    );
  }
};

Level009.metadata = {
  title: "Count to 100", // Level title
  author: "revelcw", // Full name
  solution:
    'Click until you get to 100. Slow down near the end to avoid clicking the "Go back to 1" button', // How to proceed
};

export default Level009;
