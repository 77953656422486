import { useState } from "react";

import Level001 from "./levels/Level001";
import Level002 from "./levels/Level002";
import Level003 from "./levels/Level003";
import Level004 from "./levels/Level004";
import Level005 from "./levels/Level005";
import Level006 from "./levels/Level006";
import Level007 from "./levels/Level007";
import Level008 from "./levels/Level008";
import Level009 from "./levels/Level009";
import Level010 from "./levels/Level010";
import Level011 from "./levels/Level011";
import Intro from "./levels/Intro";
import Win from "./levels/Win";
import users from "./users.json";

const levels = [
  Intro,
  Level001,
  Level002,
  Level003,
  Level004,
  Level005,
  Level006,
  Level007,
  Level008,
  Level009,
  Level010,
  Level011,
  Win,
];

export const useDelete = () => {
  const [level, setLevel] = useState(10);
  const onDelete = () => {
    setLevel((l) => l + 1);
  };
  const goToLevel = (xlevel) => {
    setLevel(xlevel);
  };

  const Level = levels[level];

  const username = Level.metadata.author;

  const user = users[username];

  const metadata = { ...Level.metadata, username, ...user };

  return { goToLevel, onDelete, Level, level, metadata };
};
