import React from "react";

const Level001 = ({ onDelete }) => (
  <>
    <div>Are you sure you want to delete it?</div>
    <button onClick={onDelete}>Delete</button>
  </>
);

Level001.metadata = {
  title: "Smash It", // Level title
  author: "revelcw", // Full name
  solution: "Click the button", // How to proceed
};

export default Level001;
