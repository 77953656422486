import React, { useState } from "react";

const Level004 = ({ onDelete }) => {
  return (
    <>
      <button>Delete</button>
      <button>Delete</button>
      <button onClick={onDelete}>Delete</button>
    </>
  );
};

Level004.metadata = {
  title: "Wait, which one?", // Level title
  author: "revelcw", // Full name
  solution: "Click the button on the far right", // How to proceed
};

export default Level004;
