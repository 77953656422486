import React from "react";
import "./styles.css";
import { useDelete } from "./leveler";

export default function App() {
  const { Level, metadata, level, ...rest } = useDelete();
  const { title, name, username, author, twitter, github, solution } = metadata;
  return (
    <div className="App">
      <header>
        <h1>Delete It: A game of removal</h1>
      </header>
      <div className="Levelcont">
        <h2>
          Level {level}: {title}
        </h2>
        <div className="Level">
          <Level {...rest} />
        </div>
        <div>{author}</div>
      </div>
    </div>
  );
}
