import React from "react";

const Win = () => (
  <>
    <div></div>
  </>
);

Win.metadata = {
  title: "You won!", // Level title
  author: "revelcw", // Full name
  solution: "Click the button", // How to proceed
};

export default Win;
