import React, { useState } from "react";
import useInterval from "@use-it/interval";

const Level007 = ({ onDelete }) => {
  const [data, setData] = useState(0);
  const getRandom = () => {
    const num = Math.floor(Math.random() * 9);
    setData(num);
  };
  useInterval(getRandom, 400);
  return (
    <div className="Level007">
      <div>
        <button
          data-white={data != 0}
          onClick={() => {
            if (data === 0) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 1}
          onClick={() => {
            if (data === 1) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 2}
          onClick={() => {
            if (data === 2) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
      </div>
      <div>
        <button
          data-white={data != 3}
          onClick={() => {
            if (data === 3) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 4}
          onClick={() => {
            if (data === 4) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 5}
          onClick={() => {
            if (data === 5) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
      </div>
      <div>
        <button
          data-white={data != 6}
          onClick={() => {
            if (data === 6) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 7}
          onClick={() => {
            if (data === 7) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
        <button
          data-white={data != 8}
          onClick={() => {
            if (data === 8) {
              onDelete();
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

Level007.metadata = {
  title: "Chase the real one", // Level title
  author: "revelcw", // Full name
  solution: "Click the button", // How to proceed
};

export default Level007;
