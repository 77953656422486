import React, { useState } from "react";

const Level002 = ({ onDelete }) => {
  const [data, setData] = useState(true);
  return (
    <>
      <div>
        <button data-white="true" onClick={() => setData(!data)}>
          Activate Below Button
        </button>
      </div>
      <button disabled={data} onClick={onDelete}>
        Delete
      </button>
    </>
  );
};

Level002.metadata = {
  title: "Pre-click Click", // Level title
  author: "revelcw", // Full name
  solution: "Press the top button to enable the delete button", // How to proceed
};

export default Level002;
